import "moment/locale/hu";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  Radio,
  RadioButtonGroup,
} from "@chakra-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useNavigate, useParams } from "react-router-dom";

import { CITY_ID } from "../../config/categories";
import { configFile } from "../../configFile";
import { IAddress } from "../../redux/addresses/AddressTypes";
import { ICartItem } from "../../redux/cart/CartTypes";
import {
  IOrderForm,
  IOrderShippingMethod,
} from "../../redux/orders/OrderTypes";
import { CartReduxProps } from "../../screens/CartScreen";
import Card from "../Card";
import { useOpenPopupStore, usePopupStore } from "../navbar/NavConstants";
import getTimeConstraints from "./dateHelpers";
import { getOpenHoursState } from "../../services/open-hours.service";
import { szolnokData, kecskemetData } from "../szolnokAddresses";

const addDays = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

type ShippingCostType = {
  kecskemet: number;
  kecskemet_to_else: number;
  szolnok: number;
  szolnok_to_else: number;
};

const defaultShippCosts: ShippingCostType = {
  kecskemet: 0,
  kecskemet_to_else: 300,
  szolnok: 0,
  szolnok_to_else: 300,
};

const defaultFormData: IOrderForm = {
  county: "",
  email: "",
  name: "",
  phone: "",
  comment: "",
  coupon: "",
  bigMoney: false,
  requestInvoice: false,
  isPrivate: false,
  isPrivateName: "",
  billingCompany: "",
  billingTaxNumber: "",
  billingAddress: "",
  shippingDate: moment(),
  shippingMethod: getOpenHoursState(true).orderEnabled
    ? IOrderShippingMethod.AtTime
    : IOrderShippingMethod.Instant,
  usedLoyaltyPoints: 0,
};

const defaultAddressFormData: IAddress = {
  name: "",
  zip: 0,
  city: "",
  street: "",
  number: "",
  floorDoor: "",
  bellComment: "",
  id: 0,
};

type Props = {
  reduxProps: CartReduxProps;
  correctCartItems: ICartItem[];
  couponData?: {
    name: string;
    discount: number;
  };
  onCouponDataChange: (data?: { name: string; discount: number }) => void;
};

function CartSummary({
  reduxProps,
  correctCartItems,
  couponData,
  onCouponDataChange,
}: Props): JSX.Element {
  const toast = useToast();
  const router = useNavigate();
  const { city } = useParams<{ city: string }>();
  const cityId = CITY_ID.find((e) => e.name === city);

  const initialPrice = correctCartItems.reduce(
    (acc, { serverPrice, quantity }) => acc + (serverPrice ?? 0) * quantity,
    0
  );
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState<IAddress>(
    reduxProps.userInfo?.addresses[0] ?? defaultAddressFormData
  );

  const [openHoursSate, setOpenHoursState] = useState(getOpenHoursState(city === 'szolnok'));

  const [formData, setFormData] = useState<IOrderForm>({
    ...defaultFormData,
    county: city === "szolnok" ? "Jász-Nagykun-Szolnok" : "Bács-Kiskun",
    name: reduxProps.userInfo?.name || "",
    email: reduxProps.userInfo?.email || "",
    phone: reduxProps.userInfo?.phone || "",
    shippingMethod:
      reduxProps.userFormData?.shippingMethod || defaultFormData.shippingMethod,
    paymentType:
      reduxProps.userFormData?.paymentType || defaultFormData.paymentType,
    comment: reduxProps.userFormData?.comment || defaultFormData.comment,
    bigMoney: reduxProps.userFormData?.bigMoney || defaultFormData.bigMoney,
    requestInvoice:
      reduxProps.userFormData?.requestInvoice || defaultFormData.requestInvoice,
    isPrivate: reduxProps.userFormData?.isPrivate || defaultFormData.isPrivate,
    isPrivateName:
      reduxProps.userFormData?.isPrivateName || defaultFormData.isPrivateName,
    billingCompany:
      reduxProps.userFormData?.billingCompany || defaultFormData.billingCompany,
    billingTaxNumber:
      reduxProps.userFormData?.billingTaxNumber ||
      defaultFormData.billingTaxNumber,
    billingAddress:
      reduxProps.userFormData?.billingAddress || defaultFormData.billingAddress,
    usedLoyaltyPoints: reduxProps.userFormData?.usedLoyaltyPoints,
  });

  const [isCardAccepted] = useState(true);
  const [, setInvalidateCardAccepted] = useState(false);

  const showErrorToast = (error: any) =>
    toast({
      title: "Hiba",
      description: error?.message,
      status: "error",
      position: "top",
      duration: 9000,
      isClosable: true,
    });

  const [wasTriggered, setWasTriggered] = useState(false);
  const [, setPopupWhatsOpen] = usePopupStore();
  const [, setPopupOpen] = useOpenPopupStore();

  const [shippingCosts, setShippingCosts] = useState<ShippingCostType>(
    defaultShippCosts
  );

  const checkCoupon = useCallback(async () => {
    const coupon = formData.coupon || reduxProps.usedCoupon;
    if (coupon) {
      const { data } = await reduxProps.OrderActions.checkPrice(
        correctCartItems
      );

      const cPrice = data.finalPrice;
      await reduxProps.OrderActions.validateCoupon(correctCartItems, coupon)
        .then((response) => {
          const discount = cPrice - response.finalPrice;
          toast({
            title: "Siker",
            description: `Kedvezmény: ${discount.toString()} Ft`,
            status: "success",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          onCouponDataChange({ name: response.name, discount });
          reduxProps.couponActions.addCoupon(coupon);
          setLoyaltyPoints(0);
        })
        .catch((error) => {
          toast({
            title: "Hiba",
            description:
              error?.response?.data?.msg ?? "Valamilyen hiba történt",
            status: "error",
            position: "top",
            duration: 3000,
            isClosable: true,
          });
          onCouponDataChange(undefined);
          setFormData({ ...formData, coupon: "" });
        });
    }
  }, [
    correctCartItems,
    formData,
    onCouponDataChange,
    reduxProps.OrderActions,
    reduxProps.couponActions,
    reduxProps.usedCoupon,
    toast,
  ]);

  useEffect(() => {
    const formDataToSave = {
      paymentType: formData.paymentType,
      shippingMethod: formData.shippingMethod,
      shippingDate: formData.shippingDate,
      comment: formData.comment,
      bigMoney: formData.bigMoney,
      requestInvoice: formData.requestInvoice,
      isPrivate: formData.isPrivate,
      isPrivateName: formData.isPrivateName,
      billingCompany: formData.billingCompany,
      billingTaxNumber: formData.billingTaxNumber,
      billingAddress: formData.billingAddress,
      usedLoyaltyPoints: 0,
    };

    reduxProps.CartActions.saveFormData(formDataToSave);
  }, [
    formData.bigMoney,
    formData.billingAddress,
    formData.billingCompany,
    formData.billingTaxNumber,
    formData.comment,
    formData.isPrivate,
    formData.isPrivateName,
    formData.paymentType,
    formData.requestInvoice,
    formData.shippingDate,
    formData.shippingMethod,
    reduxProps.CartActions,
  ]);

  /**
   * If shipping method is pickup change the payment type to card, because it's the only valid option
   * for this type of shipment
   * @param shippingMethod
   */
  const updateShippingMethod = (shippingMethod: IOrderShippingMethod): void => {
    let paymentType =
      shippingMethod === IOrderShippingMethod.Pickup
        ? "CARD"
        : formData.paymentType;
    const selectedNewDateOfWeek = moment().get("day");
    const contrs = getTimeConstraints(selectedNewDateOfWeek)[0];

    setFormData({
      ...formData,
      shippingMethod: shippingMethod,
      shippingDate: moment().set({ hour: contrs?.hour, minute: contrs?.min }),
      paymentType,
    });
  };

  // Set coupon code if its in the query

  useEffect(() => {
    /** 
    const urlCouponQuery = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlCouponQuery.entries());
    if (params?.withCoupon) {
      setFormData({ ...formData, coupon: params.withCoupon });
    }
    */
  }, []);

  useEffect(() => {
    setLoyaltyPoints(0);
  }, [initialPrice]);

  /** 
  useEffect(() => {
    const cartSize = correctCartItems.reduce(
      (acc, currVal) => acc + currVal.quantity,
      0
    );
    if (
      cartSize > 0 &&
      (couponData || formData.coupon || reduxProps.usedCoupon)
    ) {
      checkCoupon();
    } else {
      onCouponDataChange(undefined);
    }
  }, [
    reduxProps.OrderActions,
    correctCartItems,
    couponData,
    formData.coupon,
    reduxProps.usedCoupon,
    checkCoupon,
    onCouponDataChange,
  ]);      */

  useEffect(() => {
    if (reduxProps.userIsLoggedIn) {
      reduxProps.AuthActions.getUserInfo();
    }
  }, [reduxProps.AuthActions, reduxProps.userIsLoggedIn]);

  useEffect(() => {
    reduxProps.OrderActions.fetchShippingCosts()
      .then((resp) => {
        setShippingCosts({
          kecskemet: null,
          kecskemet_to_else: resp.data.kecskemetToElse,
          szolnok: null,
          szolnok_to_else: resp.data.szolnokToElse,
        });
      })
      .catch((error: Error) => {
        toast({
          title: "Hiba",
          description: error.message,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      });
  }, [
    reduxProps.OrderActions,
    reduxProps.OrderActions.fetchShippingCosts,
    toast,
  ]);

  function openDrinkPopup() {
    if (!wasTriggered) {
      setPopupWhatsOpen("order");
      setPopupOpen(true);
    }
    setWasTriggered(true);
  }

  function openPopups() {
    openDrinkPopup();
    return false;
  }
  const isCardValid = () =>
    !(formData.paymentType === "CARD" && !isCardAccepted);

  function validateFormData() {
    if (formData.county === "") {
      throw new Error("Kérlek adj meg egy megyét!");
    }
    if (!isCardValid()) {
      setInvalidateCardAccepted(true);
      throw new Error("Kérlek fogadd el a bankártyás fizetés feltételeit");
    }
    // @ts-ignore
    if (formData.shippingMethod === "") {
      throw new Error("Kérjük válassz szállítási módot");
    }
    if (formData.name === "") {
      throw new Error("Nem találtunk nevet, megadtad?");
    }
    if (formData.email === "") {
      throw new Error("Nem találtunk email címet, megadtad?");
    }
    if (formData.isPrivate && formData.isPrivateName === "") {
      throw new Error("Kérjük töltsd ki helyesen a számlázási adatokat!");
    }
    if (formData.isPrivate && formData.billingAddress === "") {
      throw new Error("Kérjük töltsd ki helyesen a számlázási adatokat!");
    }
    if (
      formData.requestInvoice &&
      formData.billingAddress === "" &&
      !formData.isPrivate
    ) {
      throw new Error("Kérjük töltsd ki helyesen a számlázási adatokat!");
    }
    if (
      formData.requestInvoice &&
      formData.billingCompany === "" &&
      !formData.isPrivate
    ) {
      throw new Error("Kérjük töltsd ki helyesen a számlázási adatokat!");
    }
    if (
      formData.requestInvoice &&
      formData.billingTaxNumber === "" &&
      !formData.isPrivate
    ) {
      throw new Error("Kérjük töltsd ki helyesen a számlázási adatokat!");
    }
    if (!formData.paymentType) {
      throw new Error("Kérlek válassz fizetési módot!");
    }
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (formData.phone === "" || !phoneRegex.test(formData.phone)) {
      throw new Error("A megadott telefonszám nem megfelelő");
    }
  }

  const getShippingCost = (): number => {
    if (selectedAddress.zip === 5000 || selectedAddress.zip === 5008) {
      const data = szolnokData;
      const findAdd = data.find(
        (address) => selectedAddress.street === address.name
      );

      let cost;
      switch (findAdd?.category) {
        case "a":
          cost = 390;
          break;
        case "b":
          cost = 590;
          break;
        case "c":
          cost = 790;
          break;
        default:
          cost = 0;
      }

      if (correctCartItems.some((item) => item.product.isVoucher)) {
        cost = 0;
      }

      return cost;
    }
    if (selectedAddress.zip === 6000) {
      const data = kecskemetData;
      const findAdd = data.find(
        (address) => selectedAddress.street === address.name
      );

      let cost;
      switch (findAdd?.category) {
        case "a":
          cost = 190;
          break;
        case "b":
          cost = 390;
          break;
        case "c":
          cost = 590;
          break;
        case "d":
          cost = 790;
          break;
        case "e":
          cost = 1090;
          break;
        default:
          cost = 0;
      }
      return cost;
    }
    if (
      selectedAddress.zip === 5051 ||
      selectedAddress.zip === 5071 ||
      selectedAddress.zip === 5081 ||
      selectedAddress.zip === 5085 ||
      selectedAddress.zip === 5091
    ) {
      return shippingCosts.szolnok_to_else;
    }
    return 0;
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  function gtagEvent() {
    //@ts-ignore
    window.gtag("event", "conversion", {
      send_to: "AW-653086677/AWD-CM_K8sADENWftbcC",
      value: totalCartPrice,
      currency: "HUF",
      transaction_id: "",
    });
  }

  async function order() {
    const openState = getOpenHoursState(city === 'szolnok');
    setOpenHoursState(openState);
    if (
      !openState.orderEnabled &&
      formData.shippingMethod !== IOrderShippingMethod.AtTime
    ) {
      toast({
        title: "Hiba",
        description:
          'Az étterem bezárt, látogass vissza holnap vagy válaszd a "Kiszállítás megadott időpontra" opciót',
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    try {
      if (openPopups()) return;

      try {
        validateFormData();
      } catch (error) {
        showErrorToast(error);
        return;
      }

      let wasError = false;

      await reduxProps.OrderActions.orderCart(
        correctCartItems,
        cityId?.id ?? -1,
        selectedAddress,
        formData
      ).catch((error) => {
        wasError = true;
        toast({
          title: "Hiba",
          description:
            error?.response?.data?.msg ?? "Hiba történt a rendelés során!",
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      });

      if (!wasError) {
        reduxProps.CartActions.emptyCart();
        toast({
          title: "Sikeres rendelés",
          description: "",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        onCouponDataChange(undefined);
        router(`/${city}/success`);
        gtagEvent();
      }
    } finally {
      setLoading(false);
    }
  }

  const handleAddressSelection = (id: string | number) => {
    const address = reduxProps.userInfo.addresses.find((add) => add?.id === id);

    setSelectedAddress(address);
  };

  const handleDateChange = (dat: any) => {
    const selectedNewDateOfWeek = moment(dat).get("day");
    const contrs = getTimeConstraints(selectedNewDateOfWeek)[0];

    setFormData({
      ...formData,
      shippingDate: moment(dat).set({ hour: contrs.hour, minute: contrs.min }),
    });
  };

  const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const hourAndMin = e.target.value.split(":");
    setFormData({
      ...formData,
      shippingDate: formData.shippingDate.set({
        hour: Number(hourAndMin[0]),
        minute: Number(hourAndMin[1]),
      }),
    });
  };

  const minimumOrderValue = 2190;
  const totalCartPrice =
    initialPrice - loyaltyPoints - (couponData?.discount ?? 0);

  const activatePoints = () => {
    const points = reduxProps.userInfo.loyaltyPoint;
    let discountPrice;
    if (points > initialPrice) {
      discountPrice = initialPrice;
    } else {
      discountPrice = points;
    }

    if (totalCartPrice < discountPrice) {
      discountPrice = totalCartPrice;
    }
    toast({
      title: "Siker",
      description: `Felhasználtál ${discountPrice} Ft értékű hűségpontot!`,
      status: "success",
      position: "top",
      duration: 3000,
      isClosable: true,
    });
    setLoyaltyPoints(discountPrice);
    setFormData({ ...formData, usedLoyaltyPoints: discountPrice });
  };

  return (
    <Card>
      <Grid
        gap={4}
        gridTemplateColumns={[
          "[colStart col2 colEnd] 1fr",
          "[colStart] 7fr [col2] 3fr [colEnd]",
          "[colStart] 3fr [col2] 1fr [colEnd]",
          "[colStart] 3fr [col2] 2fr [colEnd]",
        ]}
      >
        <Heading gridColumn="colStart / colEnd" size="sm" as="h4">
          Szállítási címem
          <Text gridColumn="colStart / colEnd">
            (Éttermi átvétel esetén is ki kell tölteni!)
          </Text>
        </Heading>

        <RadioButtonGroup
          gridColumn="colStart / colEnd"
          onChange={(e) => handleAddressSelection(e)}
          value={selectedAddress?.id}
        >
          {reduxProps.userInfo?.addresses?.map((address) => {
            return (
              <Radio
                key={address?.id}
                variantColor="red"
                value={address?.id}
                style={{ minWidth: "100%" }}
              >
                {address.city}, {address.street} {address.number}{" "}
                {address.floorDoor}
              </Radio>
            );
          })}
        </RadioButtonGroup>

        <Heading gridColumn="colStart / colEnd" size="sm" as="h4">
          Telefonszám{" "}
          <Text fontSize="sm" as="i" fontWeight="initial">
            (0620xxxxxxx formában)
          </Text>
        </Heading>
        <Input
          gridColumn="colStart / colEnd"
          type="tel"
          backgroundColor="gray.300"
          placeholder="Telefonszám"
          value={formData.phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({ ...formData, phone: e.target.value });
          }}
        />
        <Heading gridColumn="colStart / colEnd" size="sm" as="h4">
          Megjegyzés
        </Heading>
        <Textarea
          gridColumn="colStart / colEnd"
          backgroundColor="gray.300"
          value={formData.comment}
          placeholder="Megjegyzés"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({ ...formData, comment: e.target.value });
          }}
        />
        <Box gridColumn="colStart / colEnd">
          <Select
            backgroundColor="gray.300"
            defaultValue={getOpenHoursState(city === 'szolnok').orderEnabled
              ? IOrderShippingMethod.AtTime
              : IOrderShippingMethod.Instant}
            value={formData.shippingMethod}
            onChange={(e) => {
              updateShippingMethod(e.target.value as IOrderShippingMethod);
            }}
          >
            <option value="">
              Válassz átvételi módot
            </option>

            {openHoursSate.orderEnabled && (
              <option value={IOrderShippingMethod.Instant}>
                Kiszállítás azonnal
              </option>
            )}

            <option value={IOrderShippingMethod.AtTime}>
              Kiszállítás megadott időpontra
            </option>

            {openHoursSate.orderEnabled && (
              <option value={IOrderShippingMethod.Pickup}>
                Személyes átvétel: {cityId?.address}
              </option>
            )}
          </Select>
        </Box>

        {formData.shippingMethod === IOrderShippingMethod.AtTime && (
          <>
            <DatePicker
              clearIcon={null}
              value={formData.shippingDate.toDate()}
              onChange={handleDateChange}
              required
              minDate={new Date()}
              maxDate={addDays(6)}
              locale="hu-HU"
            />
            <Select
              value={`${formData.shippingDate.get(
                "hour"
              )}:${formData.shippingDate.get("minute")}`}
              gridColumn={["colStart", "col2"]}
              backgroundColor="gray.300"
              onChange={handleHourChange}
            >
              {getTimeConstraints(
                moment(formData.shippingDate).get("day")
              )?.map(({ hour, min }, index) => (
                <option value={`${hour}:${min}`} key={index}>
                  {hour}:{min === 0 ? "00" : min}
                </option>
              ))}
            </Select>
          </>
        )}

        <Box gridColumn="colStart / colEnd">
          <Select
            backgroundColor="gray.300"
            defaultValue=""
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (
                e.target.value === "CASH" ||
                e.target.value === "CARD" ||
                e.target.value === "CARD_LOCATION" ||
                e.target.value === "OTPDIRECT"
              )
                setFormData({
                  ...formData,
                  paymentType: e.target.value,
                });
            }}
            value={formData.paymentType}
          >
            <option value="" disabled>
              Válassz fizetési módot
            </option>

            <option value="CARD">
              Fizetés azonnal bankkártyával(Visa, MasterCard)
            </option>

            <option value="OTPDIRECT">
              Fizetés OTP szép kártyával azonnal
            </option>
            <option value="CARD_LOCATION">
              Fizetés bankkártyával vagy szép kártyával átvételkor
            </option>

            {formData.shippingMethod !== IOrderShippingMethod.Pickup && (
              <>
                <option value="CASH">Fizetés helyszínen készpénzzel</option>
              </>
            )}
          </Select>
        </Box>
        <Box
          pl={[4, 0]}
          gridColumn="colStart / colEnd"
          display={formData.paymentType === "CASH" ? "inherit" : "none"}
        >
          <Checkbox
            isChecked={formData.bigMoney}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({
                ...formData,
                bigMoney: e.target.checked,
              });
            }}
          >
            Nagy címlettel fizetek
          </Checkbox>
        </Box>

        <Box pl={[5, 0]} gridColumn="colStart / colEnd">
          <Checkbox
            isChecked={formData.requestInvoice}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({
                ...formData,
                requestInvoice: e.target.checked,
              });
            }}
          >
            Számlát kérek
          </Checkbox>
        </Box>

        {formData.requestInvoice && (
          <>
            <Checkbox
              isChecked={formData.isPrivate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({
                  ...formData,
                  isPrivate: e.target.checked,
                });
              }}
            >
              Magánszemély vagyok
            </Checkbox>

            {!formData.isPrivate && (
              <>
                <Input
                  backgroundColor="gray.300"
                  placeholder="Cégnév"
                  gridColumn="colStart"
                  value={formData.billingCompany}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      billingCompany: e.target.value,
                    });
                  }}
                />
                <Input
                  backgroundColor="gray.300"
                  placeholder="Adószám"
                  value={formData.billingTaxNumber}
                  gridColumn={["colStart", "col2"]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      billingTaxNumber: e.target.value,
                    });
                  }}
                />
              </>
            )}
            {formData.isPrivate && (
              <>
                <Input
                  backgroundColor="gray.300"
                  placeholder="Név"
                  value={formData.isPrivateName}
                  gridColumn="colStart / colEnd"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({
                      ...formData,
                      isPrivateName: e.target.value,
                    });
                  }}
                />
              </>
            )}
            <Box gridColumn="colStart / colEnd">
              <Input
                backgroundColor="gray.300"
                placeholder="Cím"
                value={formData.billingAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    billingAddress: e.target.value,
                  });
                }}
              />
            </Box>
            <Divider borderColor="gray.400" gridColumn="colStart / colEnd" />
          </>
        )}
        {!loyaltyPoints && (
          <>
            <Heading gridColumn="colStart / colEnd" size="sm" as="h4" mr={1}>
              Van kuponod?
            </Heading>
            <Input
              gridColumn="colStart"
              backgroundColor="gray.300"
              placeholder="Kuponkód"
              value={formData.coupon}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, coupon: e.target.value });
              }}
            />
            <Button
              gridColumn={["colStart", "col2"]}
              variantColor="red"
              onClick={checkCoupon}
            >
              Aktiválás
            </Button>
          </>
        )}
        {reduxProps.userIsLoggedIn && !couponData?.discount && (
          <>
            <Heading
              gridColumn="colStart / colEnd"
              textAlign="center"
              size="sm"
              as="h4"
              mr={1}
            >
              Hűségpontjaim: {reduxProps.userInfo?.loyaltyPoint}
            </Heading>

            {reduxProps.userInfo?.loyaltyPoint !== 0 && (
              <Button
                gridColumn="colStart / colEnd"
                variantColor="red"
                onClick={activatePoints}
              >
                Beváltás
              </Button>
            )}
          </>
        )}

        <Divider borderColor="gray.400" gridColumn="colStart / colEnd" />
      </Grid>
      <Grid
        mt={4}
        gap={4}
        gridTemplateColumns={[
          "[colStart col2 colEnd] 1fr",
          "[colStart] 1fr [col2] 1fr [colEnd]",
          "[colStart] 3fr [col2] 2fr [colEnd]",
          "[colStart] 2fr [col2] 3fr [colEnd]",
        ]}
      >
        <Heading gridColumn="colStart / col2" size="sm" as="h4">
          Összegzés
        </Heading>
        <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
          <Text>Termékek:</Text>
          <Text position="absolute" right={0}>
            {initialPrice} Ft
          </Text>
        </Flex>
        <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
          {loyaltyPoints === 0 ? null : (
            <>
              <Text>Hűségpont:</Text>
              <Text position="absolute" right={0}>
                - {loyaltyPoints} Ft
              </Text>
            </>
          )}
        </Flex>
        {couponData && (
          <>
            <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
              <Text>Felhasznált kupon:</Text>
            </Flex>
            <Flex gridColumn="col2 / colEnd" pl={[3, 0]} position="relative">
              <Text position="relative" right={0}>
                <li>
                  <i> {couponData?.name}</i>
                </li>
              </Text>
            </Flex>
            <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
              <Text>Kedvezmény: </Text>
              <Text position="absolute" right={0}>
                {` - ${couponData?.discount}`} Ft
              </Text>
            </Flex>
          </>
        )}
        <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
          {getShippingCost() === 0 ? (
            <Text>Díjtalan kiszállítás</Text>
          ) : (
            <>
              <Text>Kiszállítási díj:</Text>
              <Text position="absolute" right={0}>
                + {getShippingCost()} Ft
              </Text>
            </>
          )}
        </Flex>

        <Divider m={0} borderColor="gray.400" gridColumn="col2 / colEnd" />
        <Flex gridColumn="col2 / colEnd" pl={[4, 0]} position="relative">
          <Text>Összesen:</Text>
          <Text position="absolute" right={0}>
            {totalCartPrice + getShippingCost()}
            Ft
          </Text>
        </Flex>
        <Button
          variantColor="green"
          gridColumn="col2 / colEnd"
          rightIcon={
            isLoading || initialPrice < minimumOrderValue
              ? undefined
              : "arrow-forward"
          }
          onClick={order}
          isDisabled={isLoading || initialPrice <= minimumOrderValue}
          onMouseEnter={openDrinkPopup}
        >
          {isLoading
            ? "Rendelés..."
            : initialPrice < minimumOrderValue
            ? `Min. érték: ${minimumOrderValue} Ft`
            : "Megrendelem"}
        </Button>
      </Grid>
      {formData.paymentType === "CARD" && (
        <Text style={{ fontSize: 11, textAlign: "justify", marginTop: 5 }}>
          {city === "szolnok"
            ? configFile.cardDisclaimerSzolnok
            : configFile.cardDisclaimerKecskemet}
          <a href="http://simplepay.hu/vasarlo-aff">
            http://simplepay.hu/vasarlo-aff
          </a>
        </Text>
      )}
    </Card>
  );
}

export default CartSummary;
