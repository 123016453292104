import { Box, Text, Input, Heading, Flex } from "@chakra-ui/core";
import { List } from "@chakra-ui/core";
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Card from "../components/Card";
import NewsActions from "../redux/news/NewsActions";
import { RootState } from "../redux/store";
import { kecskemetData, szolnokData } from "../components/szolnokAddresses";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";

const OpenHoursScreen = () => {
  return (
    <>
      <Navbar />
      <Box as="main" mt={4} color="black">
        <Card shadow="2px 2px 2px 0px rgba(0,0,0,0.75)">
          <h2>Szolnok rendelésfelvétel:</h2>
          <br></br>
          <ul style={{marginLeft: 25}}>
            <li>12.23. 9:30-18:00</li>

            <li>12.24-25. ZÁRVA</li>

            <li>12.26. 12:00-22:00</li>

            <li>12.31. 9:30-18:00</li>

            <li>01.01. 14:00-22:00</li>
          </ul>
<br></br>
          <h2>Kecskemét rendelésfelvétel:</h2>
          <br></br>
          <ul style={{marginLeft: 25}}>
            <li>12.23.10:00-18:00</li>

            <li>12.24-01.01. ZÁRVA</li>

            <li>01.02. 10:00-22:30</li>
          </ul>
        </Card>
      </Box>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allNews: state.newsReducer.newsData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    NewsActions: NewsActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(OpenHoursScreen);
