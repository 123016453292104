import { configFile } from '../configFile';
import {
  addHours,
  addMinutes,
  getDay,
  startOfDay,
  intervalToDuration,
} from 'date-fns';

function parseTime(t: string): { hours: number; minutes: number } {
  const [hours, minutes] = t.split(':').map(Number);

  return { hours, minutes };
}

function dateFromTime({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}): Date {
  return [startOfDay(new Date())]
    .map((d) => addHours(d, hours))
    .map((d) => addMinutes(d, minutes))[0];
}

function dateBetween(
  target: Date,
  lowerBundary: Date,
  upperBundary: Date
): boolean {
  return (
    target.getTime() >= lowerBundary.getTime() &&
    target.getTime() <= upperBundary.getTime()
  );
}

export interface IOpenHoursState {
  timeLeft: number;
  warningState: 'none' | 'warning';
  orderEnabled: boolean;
}

export function getOpenHoursState(isSzolnok): IOpenHoursState {
  const now = new Date();
  const dayOfWeek = getDay(now);

  const { from, to } = configFile.openHours.find((entry) =>
    entry.days.some((day) => day === dayOfWeek)
  )!;

  const openfrom = dateFromTime(parseTime(from));
  const openUntil = dateFromTime(parseTime(to));

  const orderClosesAt = addMinutes(
    openUntil,
    -configFile.openHoursCloseOrderOffset
  );
  const warningShowsFrom = addMinutes(
    openUntil,
    -configFile.openHoursWarningOffset
  );

  const orderEnabled = dateBetween(now, openfrom, orderClosesAt);
  const warningState =
    orderEnabled && dateBetween(now, warningShowsFrom, orderClosesAt)
      ? 'warning'
      : 'none';

  const { hours, minutes } = intervalToDuration({
    start: now,
    end: orderClosesAt,
  });

  const timeLeft = hours! * 60 + minutes!;

  return {
    timeLeft,
    warningState,
    orderEnabled,
  };
}
