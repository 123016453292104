import "./App.scss";

import { Link } from "@chakra-ui/core";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  generatePath,
  useLocation,
  useParams,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { configFile } from "./configFile";
// import { PersistGate } from 'redux-persist/integration/react';
import configureAxios from "./configureAxios";
import AuthActions from "./redux/auth/AuthActions";
import reduxStore from "./redux/store";
import RouterApp from "./RouterApp";
import { Snowflakes } from "./snowflakes";
import CompetitionLogo from "./components/CompetitionLogo";

const App = () => {
  const { store, persistor } = reduxStore();

  useEffect(() => {
    configureAxios({ serverBaseUrl: configFile.serverBaseUrl }, store.dispatch);
    AuthActions(store.dispatch).sessionMaybeAvailable();
  }, [store.dispatch]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <CompetitionLogo />
          <Snowflakes />
          <RouterApp />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
